/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { HomeIcon, ChartPieIcon, FolderIcon, BoltIcon } from '@heroicons/react/24/outline'
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import clsx from 'clsx'
import SettingsInputComponentOutlinedIcon from '@mui/icons-material/SettingsInputComponentOutlined'
import { StarIcon } from '@heroicons/react/24/outline'
import { TableIcon } from '@radix-ui/react-icons'
let adminmenus = [
    { name: 'Home', href: '/dashboard', icon: HomeIcon, current: true },
    { name: 'My Tables', href: '/dashboard/tables', icon: TableIcon, current: true },
    { name: 'Workspaces', href: '/dashboard/workspaces', icon: FolderIcon, current: true },
    {
        name: 'Tools',
        icon: BoltIcon,
        current: false,
        children: [
            { name: 'Tool list', href: '/dashboard/tools' },
            { name: 'New Tool', href: '/dashboard/tools/new' }
        ]
    },
    {
        name: 'Providers',
        icon: DynamicFeedOutlinedIcon,
        current: false,
        children: [
            { name: 'Provider list', href: '/dashboard/provider' },
            { name: 'New provider', href: '/dashboard/provider/new' },
            { name: 'Cookie Providers', href: '/dashboard/cookie-provider' }
        ]
    },
    {
        name: 'Categories',
        icon: DynamicFeedOutlinedIcon,
        current: false,
        children: [
            { name: 'Category list', href: '/dashboard/category' },
            { name: 'New Category', href: '/dashboard/category/new' }
        ]
    },
    {
        name: 'Scrapes',
        icon: DynamicFeedOutlinedIcon,
        current: false,
        children: [
            { name: 'Scrape list', href: '/dashboard/ap-scrape' },
            { name: 'New Scrape', href: '/dashboard/ap-scrape/new' }
        ]
    },
    { name: 'Analytics', href: '/dashboard/error-analytics', icon: ChartPieIcon, current: false,
        children: [
            { name: 'Error Analytics', href: '/dashboard/error-analytics' },
            { name: 'Batch Analytics', href: '/dashboard/batch/dashboard' },
            { name: 'Usage', href: '/dashboard/usage' },
            { name: 'Users Report', href: '/dashboard/users' },
            { name: 'Runs', href: '/dashboard/cell-runs',  },
        ]
     },
    { name: 'Plans', href: '/dashboard/plans', icon: ChartPieIcon, current: false },
   
    { name: 'Blogs', href: '/dashboard/blogs', icon: ChartPieIcon, current: false },
    { name: 'System Connections', href: '/dashboard/system-connections', icon: SettingsInputComponentOutlinedIcon, current: true }
]
let usermenus = [
    { name: 'Home', href: '/dashboard', icon: HomeIcon, current: true },
    { name: 'My Tables', href: '/dashboard/tables', icon: TableIcon, current: true },
    { name: 'Workspaces', href: '/dashboard/workspaces', icon: FolderIcon, current: true },
    { name: 'Usage', href: '/dashboard/usage', icon: ChartPieIcon, current: false },
    {
        name: 'Tools',
        icon: BoltIcon,
        current: false,
        href: '/dashboard/tools'
    },
]
const chainermenus = [
    { name: 'Home', href: '/dashboard', icon: HomeIcon, current: true },
    { name: 'My Tables', href: '/dashboard/tables', icon: TableIcon, current: true },
    { name: 'Workspaces', href: '/dashboard/workspaces', icon: FolderIcon, current: true },
    { name: 'Usage', href: '/dashboard/usage', icon: ChartPieIcon, current: false }
]
const navigation2 = [
    { name: 'Connections', href: '/dashboard/connections', icon: SettingsInputComponentOutlinedIcon, current: true },
    {
        name: 'Upgrade',
        icon: StarIcon,
        current: false,
        href: '/dashboard/upgrade'
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function NavItem({ item }) {
    const location = useLocation()
    const isCurrent = (href, hasSubMenus) => location.pathname === href || (hasSubMenus && location.pathname.includes(href))

    const isCurrent2 = (href, exact = false) => {
        return exact ? location.pathname === href : (location.pathname ?? '').startsWith(href)
    }
    const isChildVisited = (item?.children ?? []).some((subItem) => isCurrent2(subItem.href))
    return (
        <li key={item.name}>
            {!item.children ? (
                <Link
                    to={item.href}
                    className={classNames(
                        isCurrent(item.href) ? 'bg-mtlite text-mtmain font-semibold' : '',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6  hover:bg-gray-100 hover:bg-gray-600/5  text-gray-700 hover:text-gray-900 '
                    )}
                >
                    <item.icon
                        aria-hidden='true'
                        className={classNames(
                            isCurrent(item.href) ? 'bg-mtlite font-semibold text-mtmain ' : '',
                            'h-6 w-6 shrink-0 text-gray-400 hover:bg-gray-100'
                        )}
                    />
                    {item.name}
                </Link>
            ) : (
                <Disclosure as='div' key={item.name} defaultOpen={isChildVisited}>
                    {({ open }) => (
                        <>
                            <DisclosureButton
                                className={clsx(
                                    isCurrent2(item.href, true) ? 'bg-mtlite font-semibold text-mtmain ' : '',
                                    'group flex w-full items-center gap-x-3 rounded-md p-2 text-left hover:bg-gray-100 hover:bg-gray-600/5 text-gray-700 hover:text-gray-900 '
                                )}
                            >
                                <item.icon aria-hidden='true' className='h-6 w-6 shrink-0 text-gray-400' />
                                {item.name}
                                <ChevronRightIcon
                                    className={clsx(
                                        'ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500',
                                        open && 'rotate-180'
                                    )}
                                />
                            </DisclosureButton>
                            <DisclosurePanel as='ul' className='mt-1 px-2' open={open}>
                                {item.children.map((subItem, i) => (
                                    <li key={`${subItem.name}${i}`} id={subItem.name+i}>
                                        <Link
                                            to={subItem.href}
                                            className={clsx(
                                                isCurrent2(subItem.href) ? 'bg-mtlite font-semibold text-mtmain ' : '',
                                                'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 hover:bg-gray-100'
                                            )}
                                        >
                                            {subItem.name}
                                        </Link>
                                    </li>
                                ))}
                            </DisclosurePanel>
                        </>
                    )}
                </Disclosure>
            )}
        </li>
    )
}
export default function Sidebar() {
    const { user } = useAuth()

    let navigation = []
    if (user?.role === 'admin') {
        navigation = adminmenus
    } else if (user?.role === 'user') {
        navigation = usermenus
    } else if (user?.role === 'chainer') {
        navigation = chainermenus
    }
    return (
        <div
            className='flex grow flex-col gap-y-5   pl-4'
            style={{
                backgroundImage: 'linear-gradient(white 4%, rgb(250, 250, 250, 0.5) 100%)'
            }}
        >
           
            <nav className='flex flex-1 flex-col'>
                <ul role='list' className='flex flex-1 flex-col gap-y-7'>
                    <li>
                        <ul role='list' className='-mx-2 space-y-1'>
                            {navigation.map((item, i) => (
                                <NavItem item={item} key={`${item.name}${i}`} />
                            ))}
                        </ul>
                    </li>
                    <li className='mx-2 mt-auto'>
                        <ul role='list' className='-mx-2 space-y-1'>
                            {navigation2.map((item, i) => (
                                <NavItem item={item}  key={`${item.name}${i}`} />
                            ))}
                        </ul>
                        
                    </li>
                </ul>
            </nav>
        </div>
    )
}
