
const initialState = {
    workspaces: [],
    templates:[],
    categories: []
}
const workspaceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_WORKSPACES':
            return {
                ...state,
                workspaces: payload
            }
        case 'SET_TEMPLATES':
            return {
                ...state,
                templates: payload
            }
            case 'SET_TABLE_ONLY_CATEGORIES':
                return {
                    ...state,
                    categories: payload
                }
        default:
            return state
    }
}
export default workspaceReducer
