/* eslint-disable import/no-anonymous-default-export */
import client from './client'

const getAllUsertable = (isTemplates) => client.get(`/table`, { params: { isTemplates } })
const getAllTable = (pagination) => client.get(`/table`, { params: pagination })
const getAllApps = () => client.get(`/table`, { params: { koalaTable: true } })

const getUsertable = (id, rowId) => client.get(rowId ? `/table/${id}?rowId=${rowId}` : `/table/${id}`)
const gettable = (id) => client.get(`/table/${id}`)

const createNewUsertable = (body) => client.post(`/table`, body)
const getschema = (body) => client.post(`/table/getschema`, body)

const updateUsertable = (id, body) => client.put(`/table/${id}`, body)

const deleteUsertable = (id) => client.delete(`/table/${id}`)
const bulkRun = (body) => client.post(`/bots/bulkRun`, body)

export default {
    createSecret: () => client.post(`/table/apikey`),
    getSecret: () => client.get(`/table/apikey`),
    getCell: (tableId, cellId) => client.get(`/table/${tableId}/cell/${cellId}`),
    getTemplate: (id) => client.get(`/table/${id}/template`),
    getCells: (id) => client.get(`/table/${id}/cells`),
    getRows: (id, pagination) => client.get(`/table/${id}/rows`, { params: pagination }),
    getTemplates: () => client.get(`/table?isTemplates=true`),
    
    gettable,
    getAllUsertable,
    getAllTable,
    getUsertable,
    getUsertableForPlayground: (id) => client.get(`/table/${id}/playground`),
    createNewUsertable,
    updateUsertable,
    deleteUsertable,
    bulkRun,
    getschema,
    getAllApps,
    getAiColumnData: (id, payload) => client.post(`/batches/${id}/aiColumnData`, payload),
    getLogs: (tableId, cellId, rowId, onlyFirst) =>
        client.get(`/table/${tableId}/cell/${cellId}/row/${rowId}/logs?onlyFirst=${onlyFirst}`),
    getTool: (payload) => client.post(`/table/tool`, payload),
    updateGlobalParams: (id, payload) => client.post(`/table/${id}/updateGlobalParams`, payload),
    markSample: (id, payload) => client.post(`/table/${id}/markSample`, payload),
    addWB: (id) => client.post(`/table/${id}/addWB`),
    cloneWb: (id, payload) => client.post(`/table/${id}/cloneWb`, payload),
    startPageBuilder: () => client.post(`/table/startPageBuilder`),
    renameTable: (id, payload) => client.post(`/table/${id}/renameTable`, payload),
    createNewWb: (payload) => client.post(`/table/createNewWb`, payload),
    renameCell: (id, payload) => client.post(`/table/${id}/renameCell`, payload),
    saveCell: (id, payload) => client.post(`/table/${id}/saveCell`, payload),
    deleteCell: (id, payload) => client.post(`/table/${id}/deleteCell`, payload),
    duplicateWb: (id) => client.post(`/table/${id}/duplicateWb`),
    runWb: (id, payload) => client.post(`/table/${id}/runWb`, payload),
    getUserTable: (id) => client.get(`/users/${id}/tables`),
    stopRunAll: (id, payload) => client.post(`/table/${id}/stopRunAll`, payload),
    getWBRun: (id) => client.get(`/table/${id}/getWBRun`),
    checkWBRun: (id, payload) => client.post(`/table/${id}/checkWBRun`, payload),
    getRowData: (id, payload) => client.post(`/table/${id}/getRowData`, payload),
    runPlayground: (id, payload) => client.post(`/table/${id}/runPlayground`, payload),
    saveAsDocument: (id, payload) => client.post(`/documents/${id}/saveAsDocument`, payload),
    updateRow: (id, payload) => client.post(`/table/${id}/updateRow`, payload),
    getSuggestions: (id, payload) => client.post(`/table/${id}/getSuggestions`, payload),
    shareWb: (payload) => client.post(`/shareWorkbook`, payload),
    getShareStatus: (payload) => client.post(`/shareWorkbook/status`, payload),
    getSharedWbPublic: (payload) => client.post(`/shareWorkbook/getSharedWbPublic`, payload),
    getSharedWb: (payload) => client.post(`/shareWorkbook/getSharedWb`, payload),
    cloneSharedWb: (id) => client.post(`/shareWorkbook/cloneSharedWb`, { id }),
    getContentWorkbook: () => client.get(`/shareWorkbook/contentWorkbook`),
    runKoalaTable: (id, payload) => client.post(`/table/${id}/runKoalaTable`, payload),
    getApps: () => client.get(`/table/getApps`),
    generateContent: (id, payload) => client.post(`/shareWorkbook/${id}/generateContent`, payload),
    uploadImg: (body) => client.post(`/shareWorkbook/uploadImg`, body),
    getUserDocs: (id) => client.get(`/users/${id}/documents`),
    getUser: (id) => client.get(`/users/${id}`),
    findRow: (id) => client.get(`/table/${id}/findrow`),
    addRow: (id, payload) => client.post(`/table/${id}/addRow`, payload),
    getLatestRuns: () => client.get(`/table/latestRuns`),
    uploadThumbnail: (id, body) => client.post(`/table/${id}/uploadThumbnail`, body),
    importCsv: (id, body) => client.post(`/table/${id}/importCsv`, body),
    getRow: (id, rowId) => client.get(`/table/${id}/row/${rowId}`),
    importAppData: (body) => client.post(`/apps/importAppData`, body),
    saveOrder: (id, payload) => client.post(`/table/${id}/saveOrder`, payload),
    runOnRows: (id, payload) => client.post(`/batches/${id}/runOnRows`, payload),
    getCredits: (id, range, timezone) => client.get(`/credits/${id}?range=${range}&timezone=${timezone}`),
    updateFilterDb: (id, payload) => client.post(`/table/${id}/updateFilterDb`, payload),
    stopEnrichment: (id) => client.post(`/batches/${id}/stopEnrichment`),
    stopRunCell: (id, payload) => client.post(`/batches/${id}/stopRunCell`, payload),
    markFavorite: (id, payload) => client.post(`/table/${id}/markFavorite`, payload),
    updateWorkspace: (id, payload) => client.post(`/table/${id}/updateWorkspace`, payload),
    makePrimary: (id, payload) => client.post(`/table/${id}/makePrimary`, payload)
}
