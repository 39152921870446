import { dispatch, state } from 'store'
import usertableApi from 'api/usertable'
import apps from 'api/apps'
import { checkForRunAiCellOnRowUpdate, titleCase, checkRefAndPrompt } from './utils'
import { isArray } from 'lodash'
import sheets from 'api/sheets'
const toast = (message, type) => {
    dispatch({ type: 'TOAST', payload: { message, type } })
}
const setLoading = (loading) => {
    dispatch({ type: 'SET_LOADING_DG', payload: loading })
}
const setCreatingNewWb = (loading) => {
    dispatch({ type: 'setCreatingNewWb', payload: loading })
}
// Get the active ID (sheet ID if available, otherwise table ID)
const getActiveId = () => {
    const st = state()
    const { currentSheet, table } = st.datagrid
    return currentSheet || table?._id
}

const getTable = async (id) => {
    try {

        dispatch({ type: 'SET_TABLE_LOADING', payload: true })
        const result = await usertableApi.gettable(id)

        if (result.status && result.data) {
            dispatch({ type: 'SET_DISABLE', payload: result.data.isDisabled === true })
            dispatch({ type: 'SET_TABLE', payload: result.data })
            dispatch({ type: 'SET_TABLE_DG', payload: result.data })
            if (result.data.cells) {
                const c = result.data.cells.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
                dispatch({ type: 'SET_CELLS_FOR_DG', payload: c })
                dispatch({ type: 'SET_HEADINGS_DG', payload: c })
            }


        } else {
            toast(`Failed to retrieve Table`, 'error')
        }
    } catch (error) {
        toast(`Failed to retrieve Table`, 'error')
    } finally {
        dispatch({ type: 'SET_TABLE_LOADING', payload: false })
        dispatch({ type: 'SET_TABLE_LOADED', payload: true })
    }
}
const getCells = async (id) => {
    try {
        dispatch({ type: 'SET_CELLS_LOADED', payload: true })
        const result = await usertableApi.getCells(getActiveId() || id)
        if (result.status && result.data) {
            const c = result.data.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: c }) // for ai cell edit different from headings
            dispatch({ type: 'SET_HEADINGS_DG', payload: c })
        } else {
            toast(`Failed to retrieve Table`, 'error')
        }
    } catch (error) {
        console.log(error)
        toast(`Failed to retrieve Table`, 'error')
    } finally {
        dispatch({ type: 'SET_CELLS_LOADED', payload: false })
    }
}

const getRows = async (id, intervalId) => {
    try {
        dispatch({ type: 'SET_ROWS_LOADED', payload: true })
        const pagination = { page: 0, limit: 20, sortBy: 'createdAt:asc' }
        const result = await usertableApi.getRows(getActiveId() || id, pagination)
        if (result.status && result.data && result.data.results) {
            const rws = result.data.results.map((row) => ({ ...row, id: row._id.toString() }))
            dispatch({ type: 'SET_ROWS_DG', payload: rws })
            if (result.data.totalResults > 200) {
                const pagination1 = { page: 0, limit: result.data.totalResults, sortBy: 'createdAt:asc', checkForTabelStatus: !!intervalId }
                const result1 = await usertableApi.getRows(id, pagination1)
                if (result1.status && result1.data && result1.data.results) {
                    const rws = result1.data.results.map((row) => ({ ...row, id: row._id.toString() }))
                    dispatch({ type: 'SET_ROWS_DG', payload: rws })
                    dispatch({ type: 'SET_TABLE_ROWS', payload: rws.map((row) => row._id) })
                    console.log('result1.data.importStatus	', intervalId, result1.data.importStatus)
                    if (intervalId && result1.data.importStatus === 'Completed') {
                        clearInterval(intervalId)
                    }
                }
            } else {
                dispatch({ type: 'SET_TABLE_ROWS', payload: rws.map((row) => row._id) })
            }
        } else {
            toast(`Failed to retrieve Table`, 'error')
        }
    } catch (error) {
        console.log(error)
        toast(`Failed to retrieve Table`, 'error')
    } finally {
        dispatch({ type: 'SET_ROWS_LOADED', payload: false })
    }
}
const fetchMoreRows = async (offset) => {
    try {
        dispatch({ type: 'SET_ROWS_LOADED', payload: true })
        const pagination = { offset }
        const result = await usertableApi.getRows(getActiveId(), pagination)
        if (result.status && result.data && result.data.length > 0) {
            const rws = result.data.map((row) => ({ ...row, id: row._id.toString() }))
            dispatch({ type: 'ADD_ROWS_DG', payload: rws })
        } else {
            toast(`Failed to retrieve Table`, 'error')
        }
    } catch (error) {
        console.log(error)
        toast(`Failed to retrieve Table`, 'error')
    } finally {
        dispatch({ type: 'SET_ROWS_LOADED', payload: false })
    }
}
const saveRow = async (row) => {
    try {

        const activeId = getActiveId()
        setLoading(true)
        const result = await usertableApi.updateRow(activeId, { ...row })
        if (result && result.data && result?.data?.deletedRowsIds) {
            dispatch({ type: 'DELETE_ROWS_DG', payload: result?.data?.deletedRowsIds ?? [] })
            if(result?.data?.error){
                toast(result?.data?.error, 'warning')
            } else {
                dispatch({ type: 'UPDATE_CELL_DG', payload: row })
            }
            
        } else {
            toast(`Failed to update row`, 'error')
        }
        setLoading(false)
    } catch (error) {
        console.log('error', error)
        toast(`Failed to update row`, 'error')
        setLoading(false)
    }
}
const updateFilterDb = async (payload) => {
    try {
        const id = getActiveId()
        usertableApi.updateFilterDb(id, payload)
    } catch (error) {
        console.log('error', error)
    }
}
const handleRename = async (item) => {
    try {
        setLoading(true)
        const result = await usertableApi.updateUsertable(item._id, item)
        if (result && result.data) {
            toast('Table updated successfully', 'success')
            dispatch({ type: 'SET_TABLE_DG', payload: result.data })
        }
        setLoading(false)
    } catch (error) {
        const errorData = error?.response?.data?.message ?? `${error?.response?.statusText}`
        toast(`Failed to update Table: ${errorData ?? ''}`, 'error')
        setLoading(false)
    }
}
const pinColumn = async (tableId, pin) => {
    try {
        setLoading(true)
        const result = await usertableApi.updateUsertable(tableId, pin)
        if (result && result.data) {
            toast('Table updated successfully', 'success')
            dispatch({ type: 'SET_TABLE_DG', payload: result.data })
        }
        setLoading(false)
    } catch (error) {
        const errorData = error?.response?.data?.message ?? `${error?.response?.statusText}`
        toast(`Failed to update Table: ${errorData ?? ''}`, 'error')
        setLoading(false)
    }
}
const makePrimary = async (tableId, payload) => {
    try {
        setLoading(true)
        const result = await usertableApi.makePrimary(tableId, payload)
        if (result.status && result.data) {
            const updatedCells = result.data.allCells
            dispatch({ type: 'SET_CELLS', payload: updatedCells })
            dispatch({ type: 'SET_HEADINGS_DG', payload: updatedCells })
            let c = updatedCells
            c = c.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: c })
            setLoading(false)
            const deletedRows = result.data.deletedRows
            if (deletedRows && deletedRows.length > 0) {
                dispatch({ type: 'DELETE_ROWS_DG', payload: deletedRows })
            }
            return updatedCells
        } else {
            toast(`Failed to save`, 'error')
        }
    } catch (error) {
        const errorData = error?.response?.data?.message ?? `${error?.response?.statusText}`
        toast(`Failed to update Table: ${errorData ?? ''}`, 'error')
        setLoading(false)
    }
}
const fetchSheet = async (sheetId) => {
    try {
        const activeSheetId = state().sheets.activeSheetId
        const result = await sheets.getSheet(sheetId)
        if (result && result.data) {
            const sheet = result.data
            dispatch({ type: 'ADD_SHEET', payload: result.data })
            if (activeSheetId === sheetId) {
                if (sheet) {
                    dispatch({ type: 'SET_SWITCH_SHEET_DG', payload: sheet })
                    dispatch({ type: 'SET_CURRENT_SHEET_DATA', payload: sheet })
                    if (sheet.cells) {
                        dispatch({ type: 'SET_CELLS_FOR_DG', payload: sheet.cells })
                        dispatch({ type: 'SET_HEADINGS_DG', payload: sheet.cells })
                    }
                    if (sheet.rows) {
                        const rws = sheet.rows.map((row) => ({ ...row, id: row._id.toString() }))
                        dispatch({ type: 'SET_ROWS_DG', payload: rws })
                        dispatch({ type: 'SET_TABLE_ROWS', payload: rws.map((row) => row._id) })
                    }
                }
            }
        }
    } catch (error) {
        const errorData = error?.response?.data?.message ?? `${error?.response?.statusText}`
        toast(`Failed to get Sheet: ${errorData ?? ''}`, 'error')
    }
}
const saveCell = async (payload, randomUUID, hideToast = false) => {
    const st = state()
    const {
        session: { user },
        datagrid: { table },
        socket: { roomId }
    } = st
    const userId = user?._id
    const disabled = userId !== table.userId
    if (disabled) {
        toast('You are not authorized to edit this table', 'error')
        return
    }
    try {
        const activeId = getActiveId()
        setLoading(payload.uiId)
        payload.roomId = roomId
        const closeHeaderInfo = payload.closeHeaderInfo
        if(closeHeaderInfo){
            delete payload.closeHeaderInfo
        }
        const result = await usertableApi.saveCell(activeId, payload)
        if (result.status && result.data) {
            if(closeHeaderInfo){
                dispatch({ type: 'SET_CLOSE_HEADER_INFO', payload: new Date().getTime() })
            }
            dispatch({ type: 'SET_CELLS', payload: result.data })
            dispatch({ type: 'SET_HEADINGS_DG', payload: result.data })
            let c = result.data
            c = c.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: c })
            setLoading(false)
            if (!hideToast) {
                toast('Saved', 'success')
            }
            const findCell = result.data.find((cell) => cell.uiId === payload?.uiId)
            const findCellIndex = result.data.findIndex((cell) => cell.uiId === payload?.uiId)

            if (findCell && findCell.childTable && findCell.childTable !== payload?.childTable) {
                const sheets = state().sheets.sheets
                const sheet = sheets.find((sheet) => sheet._id === findCell.childTable)
                if (!sheet) {
                    fetchSheet(findCell.childTable)
                }
            }
            if (randomUUID) {
                setTimeout(() => {
                    dispatch({ type: 'SCROLL_HEADER', payload: { index: randomUUID ? findCellIndex : undefined } })
                }, 300)
            }
            return result.data
        } else {
            toast(`Failed to save`, 'error')
        }
        setLoading(false)
    } catch (error) {
        console.log({ error })
        toast(`Failed to save`, 'error')
        setLoading(false)
    }
}
const saveOrder = async (items) => {
    try {
        const st = state()
        const {
            session: { user },
            datagrid: { table }
        } = st
        const id = getActiveId()
        const disabled = user?._id !== table.userId
        if (disabled) {
            toast('You are not authorized to edit this table', 'error')
            return
        }
        setLoading(true)
        items = items.map((item) => ({ _id: item._id, index: item.index }))
        usertableApi.saveOrder(id, { items })
    } catch (error) {
        console.log({ error })
        toast(`Failed to save`, 'error')
        setLoading(false)
    }
}
const onDeleteColumn = async (column) => {
    try {
        const st = state()
        const {
            session: { user },
            datagrid: { table }
        } = st
        const disabled = user?._id !== table.userId
        if (disabled) {
            toast('You are not authorized to edit this table', 'error')
            return
        }
        setLoading(true)
        const id = getActiveId()
        const result = await usertableApi.deleteCell(id, { column })
        if (result.status && result.data) {
            dispatch({ type: 'SET_CELLS', payload: result.data })
            dispatch({ type: 'SET_HEADINGS_DG', payload: result.data })
            let c = result.data
            c = c.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: c })
            toast('Column deleted successfully', 'success')
        } else {
            toast(`Failed to delete column`, 'error')
        }
        setLoading(false)
    } catch (error) {
        console.log({ error })
        toast(`Failed to delete column`, 'error')
        setLoading(false)
    }
}
const createNewWorkbook = async (type) => {
    const st = state()
    const {
        datagrid: { table }
    } = st
    const id = table?._id
    if (type === 'clone') {
        try {
            setCreatingNewWb(type)
            const result = await usertableApi.cloneWb(id)
            if (result && result.data && result?.data?.status) {
                toast('Table cloned successfully', 'success')
                window.location = `/dashboard/table/${result.data.table._id}`
                setCreatingNewWb(false)
            } else {
                toast(`Failed to clone  Table`, 'error')
                setCreatingNewWb(false)
            }
        } catch (error) {
            toast(`Failed to clone table`, 'error')
            setCreatingNewWb(false)
        }
    } else if (type === 'row') {
        try {
            setCreatingNewWb(type)
            const result = await usertableApi.addWB(id)
            if (result && result.data && result?.data?.status) {
                setCreatingNewWb(false)
                dispatch({ type: 'ADD_ROW_DG', payload: result.data })
            } else {
                toast(`Failed to create new row`, 'error')
                setCreatingNewWb(false)
            }
            return true
        } catch (error) {
            const errorData = error?.response?.data || `${error?.response?.status}: ${error?.response?.statusText}`
            toast(`Failed to create new row: ${errorData}`, 'error')
            setCreatingNewWb(false)
        }
    }
}


const deleteRows = async (selectedRows) => {
    const id = getActiveId()
    try {
        setLoading(true)
        toast('Deleting rows', 'info')
        const result = await usertableApi.updateUsertable(id, { deleteRows: true, ids: selectedRows })
        if (result.status && result.data && result?.data?.status) {
            dispatch({ type: 'DELETE_ROWS_DG', payload: selectedRows })
            toast('Rows deleted successfully', 'success')

        } else {
            toast(`Failed to delete rows`, 'error')
        }
        dispatch({ type: 'SET_SELECTED_ROWS', payload: [] })
        dispatch({ type: 'SET_SELECTION_DG', payload: undefined })
        setLoading(false)
    } catch (error) {
        console.log(error)
        toast(`Failed to delete rows:`, 'error')
        setLoading(false)
    }
}
const runOnSelected = async (rowsIds, cellId, isReRun = false) => {
    try {
        const st = state()
        const { filteredRows, headings } = st.datagrid
        const id = getActiveId()
        const { socketId, roomId } = st.socket
        let updatedRows = filteredRows
        const col = headings.find((h) => h._id === cellId)
        rowsIds = rowsIds.filter((rowId) => {
            const row = filteredRows.find((r) => r._id === rowId)
            const rowIndex = filteredRows.findIndex((r) => r._id === rowId)
            if (row) {
                const hasPrompt = checkRefAndPrompt(filteredRows, headings, col, rowIndex)
                if (!hasPrompt) {
                    return false
                }
                if (isReRun) {
                    row[cellId] = 'Queued...'
                    updatedRows[rowIndex] = row
                    return true
                }

                let cellData = row[cellId]
                if (!cellData || cellData === '' || cellData === 'null' || cellData === 'undefined') {
                    row[cellId] = 'Queued...'
                    updatedRows[rowIndex] = row
                    return true
                }
                cellData = cellData.toString().toLowerCase().trim()
                if (cellData === 'queued...') {
                    return false
                } else if (cellData === 'processing...') {
                    return false
                } else if (cellData === 'error' || cellData === 'not found') {
                    row[cellId] = 'Queued...'
                    updatedRows[rowIndex] = row
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })
        if (rowsIds.length === 0) {
            toast('No row found to run', 'error')
            return
        }

        dispatch({ type: 'SET_ROWS_DG', payload: updatedRows })
        await usertableApi.getAiColumnData(id, {
            entity: 'tables',
            socketId,
            roomId,
            rowsIds,
            runOnSelectedRows: true,
            cellId
        })
        dispatch({ type: 'SET_ENRICHMENT_STATUS_SHEET', payload: {
            tableId: id,
            isProcessing: true
        } })
    } catch (e) {
        console.log('error', e)
        const error = 'Failed to run on selected rows'
        toast(error, 'error')
    }
}

const uploadCSV = async (data) => {
    try {
        const st = state()
        const {
            datagrid: {
                table: { _id },
                headings
            }
        } = st
        const { csvData, mappedCols } = data
        const csvDatum = { data: csvData, uploading: false, mappedCols, tableId: _id }
        await handleUploadCSV(csvDatum, headings, _id)
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}
const handleSheetData = async (data, tableId) => {
    try {
        if (!data || !data.rows) {
            return false
        }
        const sheets = state().sheets.sheets
        const activeSheetId = getActiveId()
        for (const sheet of sheets) {
            if (activeSheetId === sheet._id && sheet._id === tableId) {
                const rws = data.rows.map((row) => ({ ...row, id: row._id.toString() }))
                dispatch({ type: 'ADD_ROWS_DG', payload: rws })
                dispatch({ type: 'ADD_TABLE_ROWS', payload: rws.map((row) => row._id) })
                dispatch({ type: 'ADD_NEW_SHEET_ROWS_COUNT', payload: { sheetId: sheet._id, rows: data.rows } })
            } else {
                dispatch({
                    type: 'APPEND_SHEET_ROWS', payload: {
                        sheetId: sheet._id,
                        rows: data.rows
                    }
                })
                dispatch({ type: 'ADD_NEW_SHEET_ROWS_COUNT', payload: { sheetId: sheet._id, rows: data.rows } })
            }
        }
        return true
    } catch (error) {
        console.log('error', error)
        return false
    }
}

const importCsv = async (data, isChunk = false, id) => {
    try {
        if (isChunk) {
            const result = await usertableApi.importCsv(id, data)
            if (data.isSheet) {
                return result
            }
            if (result.status && result.data && result.data.rows) {
                dispatch({ type: 'ADD_ROWS_DG', payload: result.data.rows })
            }
            return true
        }
        const st = state()
        const {
            datagrid: {
                headings
            }
        } = st
        setLoading(true)
        const result = await usertableApi.importCsv(id, data)
        if (result.status && result.data && result.data.cells) {

            toast(`CSV imported Successfully`, 'success')
            const c = result.data.cells.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'ADD_AI_GENERATED_CELLS', payload: c })
            dispatch({ type: 'ADD_ROWS_DG', payload: result.data.rows })
            const cells = [...headings, ...c]
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: cells })
        } else {
            setLoading(false)
            toast(`Failed to import CSV`, 'error')
        }
        return result
    } catch (error) {
        console.log('error', error)
        const errorData = error?.response?.data?.message || `${error?.response?.status}: ${error?.response?.statusText}`
        toast(`Failed to import CSV: ${errorData ?? ''}`, 'error')
        if (isChunk) {
            return false
        }
        return error
    }
}
const handleUploadCSV = async (csv, cols, id) => {
    try {
        setLoading(true)
        const csvData = csv.data
        const mappedCols = csv.mappedCols
        const headers = (csvData[0] ?? []).map((header) => header.toString().trim().toLowerCase())
        const data = csvData.slice(1)
        // make chunks of 100 rows
        const chunks = []
        const colsMap = cols.reduce((acc, curr) => {
            acc[curr.label.toString().trim().toLowerCase()] = curr._id
            return acc
        }, {})
        const headerMap = {}
        headers.map((heading, index) => {
            const label = mappedCols[heading]
            const findLabelId = label ? colsMap[label.toString().trim().toLowerCase()] : null
            headerMap[index] = findLabelId
        })
        const chunkSize = 100000000
        for (let i = 0; i < data.length; i += chunkSize) {
            chunks.push(data.slice(i, i + chunkSize))
        }
        if (data.length === 0) {
            chunks.push([])
        }
        for (let i = 0; i < chunks.length; i++) {
            // append headers to each chunk
            const chunk = chunks[i].map((row) => {
                const newRow = {}
                Object.keys(row).map((key, index) => {
                    const colId = headerMap[index]
                    if (colId) {
                        newRow[colId] = row[key]
                    }
                })
                return newRow
            })
            const isLast = i === chunks.length - 1
            const result = await importCsv({ chunk, isChunk: true, isLast, isSheet: csv.isSheet }, true, id)
            if (csv.isSheet) {
                return handleSheetData(result?.data ?? {}, id)
            }

            if (!result) {
                toast(`Failed to import CSV`, 'error')
                setLoading(false)
                getTable(id)
                return
            }
            if (isLast) {
                toast(`CSV imported successfully`, 'success')
                setLoading(false)
                getTable(id)
            }
        }
    } catch (error) {
        console.log('error', error)
        const errorData = error?.response?.data?.message || `${error?.response?.status}: ${error?.response?.statusText}`
        toast(`Failed to import CSV: ${errorData ?? ''}`, 'error')
        setLoading(false)
    }
}

const startScrape = async (url, prompt, scrapperConfig) => {
    try {
        const st = state()
        const {
            socket: { roomId, socketId },
            datagrid: {
                table: { _id },
                headings
            }
        } = st
        setLoading(true)
        const result = await apps.startScrape(_id, { url, prompt, roomId, socketId, scrapperConfig })
        if (result.status && result.data) {
            toast(`Scrape started successfully`, 'success')
        }
        //     dispatch({ type: 'ADD_ROWS_DG', payload: result.data })
        // } else if (result.status && result.data && result.data.chunks) {
        //     setLoading(false)
        //     toast(`Importing data...`, 'info')
        //     getTable()
        // } else {
        //     setLoading(false)
        //     toast(`Failed to start scrape`, 'error')
        // }
        setLoading(false)
        return result
    } catch (error) {
        toast(`Failed to start scrape`, 'error')
        setLoading(false)
        return error
    }
}
const runAiCellOnRowUpdate = async (row, cells) => {
    const doTest = true
    if (doTest) return
    const st = state()
    const {
        datagrid: { headings }
    } = st
    const cellsIds = checkForRunAiCellOnRowUpdate(row, cells ?? headings)
    if (cellsIds && cellsIds.length > 0) {
        cellsIds.forEach((cellId) => {
            dispatch({ type: 'UPDATE_CELL_DATA_DG', payload: { rowId: row._id, cellId: cellId, value: 'Queued...' } })
            runOnSelected([row._id], cellId)
        })
    }
}
const runOnSelectedRows = async (payload) => {
    try {
        const id = getActiveId()
        await usertableApi.runOnRows(id, payload)
        dispatch({ type: 'SET_ENRICHMENT_STATUS_SHEET', payload: {
            tableId: id,
            isProcessing: true
        } })
        toast('AI cells queued successfully', 'success')
    } catch (error) {
        console.log('error', error)
        toast(`Failed to run on selected rows`, 'error')
    }
}
const stopEnrichment = async () => {
    try {
        const id = getActiveId()
        await usertableApi.stopEnrichment(id)
        dispatch({ type: 'SET_ENRICHMENT_STATUS_SHEET', payload: {
            tableId: id,
            isProcessing: false
        } })
        toast('Enrichment stopped successfully', 'success')
    } catch (error) {
        console.log('error', error)
        toast(`Failed to stop enrichment`, 'error')
    }
}
const runOnSelectedCells = async () => {
    try {
        const st = state()
        const {
            datagrid: {
                highlightedCells,
                filteredRows
            }
        } = st
        const id = getActiveId()
        //to do
        const aiCellIds = highlightedCells.filter((item) => item.isAi)
        if (aiCellIds.length === 0) {
            toast('No AI cell found', 'error')
            return
        }
        if (highlightedCells.length === 0) {
            toast('No Cell selected', 'error')
            return
        }
        const toRun = []
        let updatedRows = filteredRows
        highlightedCells.forEach((item) => {
            const { rowId, cellId, isAi, rowIndex } = item
            if (isAi) {
                toRun.push({ rowId, cellId })
                const findRow = updatedRows[rowIndex]
                if (findRow) {
                    findRow[cellId] = 'Queued...'
                }
            }
        })
        if (toRun.length === 0) {
            toast('No AI cell found to run', 'error')
            return
        }
        dispatch({ type: 'SET_ROWS_DG', payload: updatedRows })
        await usertableApi.runOnRows(id, toRun)
        toast('AI cells queued successfully', 'success')
        dispatch({ type: 'SET_ENRICHMENT_STATUS_SHEET', payload: {
            tableId: id,
            isProcessing: true
        } })
        dispatch({ type: 'SET_SELECTION_DG', payload: undefined })
    } catch (error) {
        console.log('error', error)
        toast(`Failed to run on selected rows`, 'error')
    }
}
const selectConnection = async (c, type) => {
    try {
        const st = state()
        const {
            datagrid: { connectionDialog }
        } = st
        const { tool, item } = connectionDialog
        tool.connectionId = c._id
        item.tools = item.tools.map((t) => {
            if (t._id === tool._id) {
                return tool
            }
            return t
        })
        const result = await saveCell(item, null, true)
        if (result) {
            if (type === 'old') {
                toast('Connection saved successfully', 'success')
            } else {
                toast('API key saved successfully', 'success')
            }

            dispatch({ type: 'CLOSE_CONNECTION_DIALOG', payload: null })
            // dispatch({ type: 'CLOSE_PROVIDER_MODAL' })
        } else {
            toast(`Failed to save API key`, 'error')
        }
    } catch (error) {
        console.log('error', error)
        toast(`Failed to save API key`, 'error')
    }
}
const handleDatagridSocketCells = (item) => {
    if (item) {
        const id = getActiveId()
        if (item && item.parentTableId && item.parentTableId === id && item.type === 'UPDATE_SINGLE_CELL') {

        } else if (item && item.message && typeof item.message === 'object' && item.type === 'UPDATE_CELL') {
            const newCells = item.message.cells
            const c = newCells.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'ADD_AI_GENERATED_CELLS', payload: c })
        } else if (item && item.type && item.type === 'SCRAPE_STATUS' && item.tableId === id) {
            if (item.isCompleted) {
                toast('Scrape completed successfully', 'success')
                dispatch({ type: 'SET_TABLE_IMPORT_STATUS', payload: 'Completed' })
                getCells(id)
                getRows(id)
            }
        }
        if (item && item.type && item.type === 'REFRESH_SHEET_ROWS') {
            const sheets = state().sheets.sheets
            const sheet = sheets.find((sheet) => sheet._id === item.tableId)
            if (sheet) {
                fetchSheet(item.tableId)
            }
        }
    }
}
const handleDatagridSocketGridData = (data) => {
    if (data) {
        const gridData = []
        if (data && isArray(data) && data.length > 0) {
            // data= data.sort((a, b) => b.time - a.time)
            // let uniqueCellIds = []
            // data = data.filter((item) => {
            //     if(item && item.cellId && uniqueCellIds.indexOf(item.cellId) === -1){
            //         uniqueCellIds.push(item.cellId)
            //         return true
            //     }
            //     return false
            // })
            data.forEach((item) => {
                if (item && item.rowId !== undefined && item.cellId !== undefined && item.type && item.type === 'UPDATE_CELL_DATA') {
                    let value =
                        item.message && typeof item.message === 'object' && item.message.gotAnswer && item.message.answer ? item.message.answer : item.message
                    gridData.push({ rowId: item.rowId, cellId: item.cellId, value, tableId: item.tableId })
                } else if (item && item.rowsIds !== undefined && item.cellId !== undefined && item.type && item.type === 'START_RUN') {
                    item.rowsIds.forEach((rowId) => {
                        gridData.push({ rowId: rowId, cellId: item.cellId, value: 'InQueue', tableId: item.tableId })
                    })
                }
            })
            if (gridData.length > 0) {
                dispatch({ type: 'UPDATE_GRID_DATA', payload: gridData })
            }
        }
    }
}
const stopRunCell = async (payload) => {
    try {
        const { cellId, rowId } = payload.data
        const id = getActiveId()
        const result = await usertableApi.stopRunCell(id, { cellId, rowId })
        if (result && result.data) {
            toast('Cell stopped successfully', 'success')
            const { rowId, cell, type, rowIndex } = payload
            dispatch({ type: 'UPDATE_CELL_DATA_DG', payload: { rowId, cellId: cell, value: 'Stopped', rowIndex } })
        } else {
            toast(`Failed to stop cell`, 'error')
        }
    } catch (error) {
        console.log('error', error)
        toast(`Failed to stop cell`, 'error')
    }
}
export {
    fetchMoreRows,
    handleDatagridSocketCells,
    handleDatagridSocketGridData,
    getTable,
    saveRow,
    saveCell,
    handleRename,
    saveOrder,
    onDeleteColumn,
    createNewWorkbook,
    deleteRows,
    runOnSelected,
    uploadCSV,
    importCsv,
    startScrape,
    runOnSelectedRows,
    runOnSelectedCells,
    selectConnection,
    runAiCellOnRowUpdate,
    toast,
    handleUploadCSV,
    getCells,
    getRows,
    updateFilterDb,
    stopEnrichment,
    pinColumn,
    stopRunCell,
    makePrimary
}
