'use client'
import { Link, Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
} from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import Sidebar from './Sidebar'
import useAuth from 'hooks/useAuth'
import { useLocation } from 'react-router-dom'
import useSocket from 'hooks/useSocket'
import { dispatch } from 'store'
import useNotifier from 'utils/useNotifier'
const userNavigation = [
    { name: 'Your profile', href: '/dashboard/user/account' },
    { name: 'Sign out', href: '/auth/logout' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DashboardLayout() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { pathname } = useLocation()
    const [showSidebar, setShowSidebar] = useState(true)
    const { user } = useAuth()
    useNotifier()
    useSocket(user)
    const checkInitialMsg = (pathname) => {
        if (pathname === '/dashboard') {
            dispatch({ type: 'SET_MESSAGES_DG', payload: [] })
        }
    }
    useEffect(() => {
        // Hide sidebar for tools list page and specific usertable paths
        if (pathname === '/dashboard/tools' || pathname.includes('/dashboard/tools/list') || pathname === '/dashboard/templates' || pathname.includes('/dashboard/templates/list')) {
            setShowSidebar(false)
        } else if (pathname.includes('/dashboard/table/')) {
            const arr = pathname
                .split('/')
                .map((item) => item.trim())
                .filter((item) => item && item.length > 5).length
            if (arr === 3) {
                setShowSidebar(false)
            } else {
                setShowSidebar(true)
            }
        } else {
            setShowSidebar(true)
        }
        checkInitialMsg(pathname)
    }, [pathname])
    const isTable = pathname.includes('/dashboard/table/')
    if (isTable) {
        return (
            <div className='h-screen w-screen flex '>
                <Outlet />
            </div>
        )
    }
    return (
        <>
            <div>
                <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-10 lg:hidden">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 flex">
                        <DialogPanel
                            transition
                            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
                        >
                            <TransitionChild>
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                                    <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon aria-hidden="true" className="size-6 text-white" />
                                    </button>
                                </div>
                            </TransitionChild>
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                <div className="flex h-16 shrink-0 items-center">
                                    <img alt='Meerkats.ai' src='/logo/logo.svg'
                                        className="h-8 w-auto"
                                    />
                                </div>
                                <Sidebar />
                            </div>
                        </DialogPanel>
                    </div>
                </Dialog>


                <div className='h-screen w-screen flex flex-col'>
                    <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                        <div className="flex h-16 shrink-0 items-center lg:w-72">
                            <Link to='/dashboard'>
                                <img alt='Meerkats.ai' src='/logo/logo.svg'
                                    className="h-8 w-auto"
                                />
                            </Link>
                        </div>
                        <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon aria-hidden="true" className="size-6" />
                        </button>

                        {/* Separator */}
                        <div aria-hidden="true" className="h-6 w-px bg-gray-200 lg:hidden" />

                        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                            <div className="relative flex flex-grow focus-within:z-10 justify-center items-center  ">
                                <div className="w-1/2 mx-auto ">
                                    {/* <form action="#" method="GET" className="grid flex-1 grid-cols-1">
                                        <input
                                            name="search"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            className="col-start-1 row-start-1 block size-full bg-white pl-8 text-base text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm/6 inset inset-1 focus:ring-0.5 focus:ring-inset focus:ring-indigo-600"
                                        />
                                        <MagnifyingGlassIcon
                                            aria-hidden="true"
                                            className="pointer-events-none col-start-1 row-start-1 size-5 self-center text-gray-400"
                                        />
                                    </form> */}
                                </div>
                            </div>
                            <div className="flex items-center gap-x-4 lg:gap-x-6">
                                {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon aria-hidden="true" className="size-6" />
                                </button> */}

                                {/* Separator */}
                                {/* <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" /> */}

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative">
                                    <MenuButton className="-m-1.5 flex items-center p-1.5">
                                        <span className="sr-only">Open user menu</span>
                                        <span className="inline-flex size-8 items-center justify-center rounded-full bg-mtmain">
                                            <span className="text-sm font-medium text-white">
                                                {user?.name?.slice(0, 1)}
                                            </span>
                                        </span>

                                    </MenuButton>
                                    <MenuItems
                                        transition
                                        className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        {userNavigation.map((item) => (
                                            <MenuItem key={item.name}>
                                                <Link
                                                    to={item.href}
                                                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                >
                                                    {item.name}
                                                </Link>
                                            </MenuItem>
                                        ))}
                                    </MenuItems>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row h-[calc(100vh-64px)]'>
                        {showSidebar ? (
                            <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col w-72 mt-16">
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 py-4">

                                    <Sidebar />
                                </div>
                            </div>) : null}
                        <main
                            className={classNames(
                                showSidebar ? 'pb-10 pt-5 bg-white px-4 sm:px-6 lg:px-8 ml-72  h-[calc(100vh-64px)] w-[calc(100%-72px)] overflow-y-auto' : 'bg-white '
                            )}
                        >
                            <div className='h-full w-full'>
                                <Outlet />
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
}
