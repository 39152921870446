
const initialState = {
    sheets: [],
    activeSheetId: null,
    currentSheetData: null,
    loading: false,
    error: null,
    table: null,
    tableLoading: false,
    sheetsLoaded: false,
    sheetsLoading: false,
}
const handleSheetGridData = (state, payload) => {
    const sheets = state.sheets
    const sheetsIds = sheets.map((sheet) => sheet._id)
    const obj= sheets.reduce((acc, sheet) => {
        acc[sheet._id] = sheet.rows
        return acc
    }, {})
    payload.forEach((item) => {
        const { rowId, cellId, value, tableId } = item
        if (!sheetsIds.includes(tableId)) return
        obj[tableId] = obj[tableId].map((row) => {
            if (row._id === rowId) {
                return {
                    ...row,
                    [cellId]: value
                }
            }
            return row
        })
    })
    return {
        sheets: sheets.map((sheet) => {
            return {
                ...sheet,
                rows: obj[sheet._id]
            }
        }),
        currentSheetData: {
            ...state.currentSheetData,
            rows: obj[state.activeSheetId]
        }
    }
}
const sheetsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'ADD_NEW_SHEET_ROWS_COUNT':
            return {
                ...state,
                sheets: state.sheets.map(sheet => {
                    if(sheet._id === payload.sheetId){
                        return {
                            ...sheet,
                            totalRows: sheet.totalRows + payload.rows.length
                        }
                    }
                    return sheet
                }),
                currentSheetData: state.activeSheetId === payload.sheetId ? {
                    ...state.currentSheetData,
                    totalRows: state.currentSheetData.totalRows + payload.rows.length
                } : state.currentSheetData
            }

        case 'SET_ENRICHMENT_STATUS_SHEET':
            return {
                ...state,
                sheets: state.sheets.map(sheet => {
                    if(sheet._id === payload.tableId){
                        return {
                            ...sheet,
                            isProcessing: payload.isProcessing,
                            importStatus: payload.isProcessing ? 'Processing' : 'Completed'
                        }
                    }
                    return sheet
                }),
                currentSheetData: state.activeSheetId === payload.tableId ? {
                    ...state.currentSheetData,
                    isProcessing: payload.isProcessing,
                    importStatus: payload.isProcessing ? 'Processing' : 'Completed'
                } : state.currentSheetData
            }   
        case 'SET_TOTAL_ROWS':
            return {
                ...state,
                sheets: state.sheets.map(sheet => {
                    if(sheet._id === payload.tableId){
                        return {
                            ...sheet,
                            totalRows: payload.totalRows,
                            importStatus: 'Completed'   
                        }
                    }
                    return sheet
                }),
                currentSheetData: state.activeSheetId === payload.tableId ? {
                    ...state.currentSheetData,
                    totalRows: payload.totalRows,
                    importStatus: 'Completed'   
                } : state.currentSheetData
            }
        case 'SET_TABLE_DG_CLEANUP':
            return {
                ...initialState
            }
        case 'SET_SHEETS_LOADING':
            return {
                ...state,
                sheetsLoading: payload,
            }
        case 'SET_SHEETS_LOADED':
            return {
                ...state,
                sheetsLoaded: payload,
            }
        case 'SET_TABLE':
            return {
                ...state,
                table: payload,
            }
        case 'SET_TABLE_LOADING':
            return {
                ...state,
                tableLoading: payload,
            }
        case 'SET_SHEETS':
            return {
                ...state,
                sheets: payload,
            }

        case 'SET_CURRENT_SHEET':
            return {
                ...state,
                activeSheetId: payload._id,

            }

        case 'SET_ACTIVE_SHEET':
            return {
                ...state,
                activeSheetId: payload
            }
        case 'SET_CURRENT_SHEET_DATA':
            return {
                ...state,
                currentSheetData: {
                    ...state.currentSheetData ?? {},
                    ...payload
                }
            }

        case 'DELETE_ROWS_DG':
            const sheetData ={
                ...state.currentSheetData,
                rows: state.currentSheetData.rows.filter(row => !payload.includes(row._id)),
                totalRows: state.currentSheetData.totalRows - payload.length
            }
            return {
                ...state,
                currentSheetData: sheetData,
                sheets: state.sheets.map(sheet => {
                    if(sheet._id === state.activeSheetId){
                        return {
                            ...sheet,
                            rows: sheet.rows.filter(row => !payload.includes(row._id)),
                            totalRows: sheetData.totalRows
                        }
                    }
                    return sheet
                })
            }

        case 'ADD_ROW_DG':
            const sheetData1 ={
                ...state.currentSheetData,
                rows: [...state.currentSheetData.rows, payload],
                totalRows: state.currentSheetData.totalRows + 1
            }
            return {
                ...state,
                currentSheetData: sheetData1,
                sheets: state.sheets.map(sheet => {
                    if(sheet._id === state.activeSheetId){
                        return sheetData1
                    }
                    return sheet
                })
            }

        case 'ADD_SHEET':
            return {
                ...state,
                sheets: state.sheets.find(sheet => sheet._id === payload._id) ? state.sheets.map(sh=>{
                    if(sh._id === payload._id){
                        return payload
                    }
                    return sh
                }) : [...state.sheets, payload]
            }

        case 'UPDATE_SHEET':
            return {
                ...state,
                sheets: state.sheets.map(sheet => 
                    sheet._id === payload._id ? { ...sheet, ...payload } : sheet
                ),
                currentSheetData: state.activeSheetId === payload._id ? payload : state.currentSheetData
            }

        case 'UPDATE_SHEET_ROWS':
            return {
                ...state,
                sheets: state.sheets.map(sheet => 
                    sheet._id === payload.sheetId ? { ...sheet, rows: payload.rows } : sheet
                ),
                currentSheetData: state.activeSheetId === payload.sheetId ? {
                    ...state.currentSheetData,
                    rows: payload.rows
                } : state.currentSheetData
            }


        case 'APPEND_SHEET_ROWS':
            return {
                ...state,
                sheets: state.sheets.map(sheet => 
                    sheet._id === payload.sheetId ? { ...sheet, rows: [...sheet.rows, ...payload.rows] } : sheet
                ),
                currentSheetData: state.activeSheetId === payload.sheetId ? {
                    ...state.currentSheetData,
                    rows: [...state.currentSheetData.rows, ...payload.rows]
                } : state.currentSheetData
            }


        case 'DELETE_SHEET':
            return {
                ...state,
                sheets: state.sheets.filter(sheet => sheet._id !== payload)
            }

        case 'SET_LOADING':
            return {
                ...state,
                loading: payload
            }

        case 'SET_ERROR':
            return {
                ...state,
                error: payload
            }

        case 'CLEAR_ERROR':
            return {
                ...state,
                error: null
            }

        case 'UPDATE_GRID_DATA':
            return {
                ...state,
                ...handleSheetGridData(state, payload)
            }

        default:
            return state
    }
}

export default sheetsReducer
